<template>
  <div class="analysis-generate" v-loading.fullscreen.lock="loading" :element-loading-text="$t('common.preparingData')">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="180px" size="small">
      <el-form-item class="search-form-gutter" :label="$t('analysis.platform')" required>
        <el-select style="width: 88px" v-model="currentPlatform" @change="changePlatform" filterable>
          <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mode" key="mode" required>
        <el-radio-group v-model="mode" @change="changeMode">
          <el-radio label="group">{{ $t('analysis.group') }}</el-radio>
          <el-radio label="detail">{{ $t('common.brand') + ' & ' + $t('common.item') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="mode === 'group' ? $t('analysis.group') + '1' : $t('analysis.date')" key="previousDates"
        required>
        <el-form-item class="inline-form-item" prop="previousGroupFristDates">
          <el-select class="date-select" v-model="form.previousGroupFristDates" clearable multiple @change="changeDates">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <label class="date-vs">VS</label>
        <el-form-item class="inline-form-item" prop="previousGroupSecondDates">
          <el-select class="date-select" v-model="form.previousGroupSecondDates" clearable multiple @change="changeDates">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="mode === 'group'" :label="$t('analysis.group') + '2'" key="currentDates">
        <el-form-item class="inline-form-item" prop="currentGroupFristDates">
          <el-select class="date-select" v-model="form.currentGroupFristDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <label class="date-vs">VS</label>
        <el-form-item class="inline-form-item" prop="currentGroupSecondDates">
          <el-select class="date-select" v-model="form.currentGroupSecondDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="mode == 'detail'" class="search-form-gutter" :label="$t('common.brand')" prop="brands" required>
        <el-select style="width: 298px" v-model="form.brands" multiple clearable>
          <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="mode == 'detail'" class="search-form-gutter" :label="$t('common.item')" prop="items" required>
        <el-select style="width: 298px" v-model="form.items" multiple clearable>
          <el-option v-for="item in itemList" :key="item.value" :label="item.label" :value="item.value"
            :disabled="item.disabled"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="disableSubmit" @click="onSubmit">{{
          $t("common.submit")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  fetchRecords,
  fetchTPSReports,
  fetchTPSItemReports,
  exportTPSReports,
  exportTPSItemReports,
  fetchUserBrands,
  fetchUserItems,
} from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { ossRoot } from "@/utils/server";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      form: {
        previousGroupFristDates: [],
        previousGroupSecondDates: [],
        currentGroupFristDates: [],
        currentGroupSecondDates: [],
        brands: [],
        items: [],
      },
      brandList: [],
      itemList: [],
      rules: {
        previousGroupFristDates: [
          {
            required: true,
            trigger: "change",
          },
        ],
        previousGroupSecondDates: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      options: [],
      timer: null,
      disableSubmit: false,
      loading: false,
      mode: "group"
    };
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      this.resetItemList();
      this.fetchOptions();
    })
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    resetItemList() {
      let t2Flatten = this.$store.getters.t2Flatten;
      this.itemList = [];
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          this.itemList.push(t);
        });
    },
    fetchOptions() {
      this.options = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.options.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    fetchBrands() {
      this.form.brands = [];
      this.brandList = [];
      fetchUserBrands({
        dates: this.datesString(),
        platform: this.currentPlatform,
      }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
        }
      });
    },
    fetchItems() {
      this.form.items = [];
      this.resetItemList();
      fetchUserItems({
        dates: this.datesString(),
      }).then((response) => {
        if (response.success && response.result) {
          this.itemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.itemList, i, e);
          });
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.checkExist((url) => {
          if (url) {
            this.showDownload(url, true);
          } else {
            this.exported();
          }
        });
      });
    },
    checkExist(cb) {
      this.disableSubmit = true;
      if (this.mode === 'detail') {
        let params = { limit: 1, period: "week", platform: this.currentPlatform };
        params.fristDates = this.form.previousGroupFristDates.join(",")
        params.secondDates = this.form.previousGroupSecondDates.join(",");
        params.brands = this.form.brands.join(",");
        params.items = this.form.items.join(",");
        fetchTPSItemReports(params)
          .then((response) => {
            if (response.success) {
              if (cb) {
                cb(
                  response.result.items && response.result.items.length > 0
                    ? response.result.items[0].url
                    : null
                );
              }
            }
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      } else {
        let params = { limit: 1, period: "week", platform: this.currentPlatform };
        params.previousGroupFristDates =
          this.form.previousGroupFristDates.join(",");
        params.previousGroupSecondDates =
          this.form.previousGroupSecondDates.join(",");
        params.currentGroupFristDates =
          this.form.currentGroupFristDates.join(",");
        params.currentGroupSecondDates =
          this.form.currentGroupSecondDates.join(",");
        fetchTPSReports(params)
          .then((response) => {
            if (response.success) {
              if (cb) {
                cb(
                  response.result.items && response.result.items.length > 0
                    ? response.result.items[0].url
                    : null
                );
              }
            }
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      }

    },
    exported() {
      if (this.mode === 'detail') {
        let params = {
          period: "week",
          platform: this.currentPlatform,
          fristDates: this.form.previousGroupFristDates.join(","),
          secondDates:
            this.form.previousGroupSecondDates.join(","),
          brands: this.form.brands.join(","),
          items: this.form.items.join(",")
        };

        this.loading = true;
        let cb = (response) => {
          if (response.success) {
            this.timer = setTimeout(() => {
              this.fetchUrl();
            }, 2000);
          }
        };
        exportTPSItemReports(params).then((response) => {
          cb(response);
        });

      } else {
        let params = {
          period: "week",
          platform: this.currentPlatform,
          previousGroupFristDates: this.form.previousGroupFristDates.join(","),
          previousGroupSecondDates:
            this.form.previousGroupSecondDates.join(","),
          currentGroupFristDates: this.form.currentGroupFristDates
            ? this.form.currentGroupFristDates.join(",")
            : null,
          currentGroupSecondDates: this.form.currentGroupSecondDates
            ? this.form.currentGroupSecondDates.join(",")
            : null,
        };

        this.loading = true;
        let cb = (response) => {
          if (response.success) {
            this.timer = setTimeout(() => {
              this.fetchUrl();
            }, 2000);
          }
        };
        exportTPSReports(params).then((response) => {
          cb(response);
        });
      }
    },
    fetchUrl() {
      this.checkExist((url) => {
        if (url) {
          this.resetForm();
          clearTimeout(this.timer);
          this.loading = false;
          this.showDownload(url);
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl();
          }, 2000);
        }
      });
    },
    showDownload(url, exist) {
      const h = this.$createElement;
      this.$msgbox({
        title: this.$t("common.prompt"),
        message: h("div", null, [
          h("i", { class: "el-icon-success" }),
          h(
            "span",
            { style: "margin-left: 10px" },
            exist
              ? this.$t("analysis.reportExist")
              : this.$t("analysis.generateSucc", { type: "TPS" })
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t("common.download"),
        cancelButtonText: exist
          ? this.$t("common.regenerate")
          : this.$t("common.cancel"),
        distinguishCancelAndClose: true,
      })
        .then((action) => {
          if (action === "confirm") {
            var a = document.createElement("a");
            a.href = ossRoot + "/" + url;
            a.download = "ai-analysis-report" + Date.now() + ".xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
          }
        })
        .catch((action) => {
          if (action === "cancel" && exist) {
            this.exported();
          }
        });
    },
    changePlatform() {
      this.fetchOptions();
    },
    changeMode() {
      this.resetForm();
      this.resetItemList();
      this.brandList = [];
    },
    resetForm() {
      this.$refs.form.resetFields();
      // 由于切换时某些el-form-item已经不再渲染，需要手动清理其prop
      this.form.brands = [];
      this.form.items = [];
      this.form.currentGroupFristDates = [];
      this.form.currentGroupSecondDates = [];
    },
    changeDates() {
      this.fetchBrands();
      this.fetchItems();
    },
    datesString() {
      let v = [];
      if (this.form.previousGroupFristDates) {
        v.push(this.form.previousGroupFristDates);
      }
      if (this.form.previousGroupSecondDates) {
        v.push(this.form.previousGroupSecondDates);
      }
      return v.length > 0 ? v.join(",") : ""
    }
  },
};
</script>

<style lang="scss" scoped>
.analysis-generate {
  .form {
    margin-top: 25px;
  }

  .date-vs {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    margin-left: 10px;
    margin-right: 10px;
  }

  .date-select {
    width: 200px;
  }

  .inline-form-item {
    display: inline-block;
    margin-bottom: 0;
  }
}
</style>